

/* (desktop) */
@media screen and (min-width: 1200px) {
    .FooterDivider {
        background-color: rgba(67, 67, 67, 1);
    }
    .TippingPointLogomark {
        width: 48px;
        height: 46px;
    }
    .TippingPointLogoText {
        width: 143px;
        height: 24px;
    }
    .SocialMediaLinks {
        width: 34px;
        height: 34px;
        cursor: pointer;
        color: #c1feaf;
        font-size: 34px !important;
    }
    .FooterLinkBlock {
        text-align: left;
    }
    .FooterHeader {
        font-size: 24px;
        color: rgba(193, 254, 175, 1);
    }
    .FooterSubtext {
        font-size: 16px;
        color: rgba(194,194,194,1);
        cursor: pointer;
    }
    .FooterBottomText {
        color: rgba(133, 133, 133, 1);
        font-size: 14px;
        text-align: left;
    }
    .FooterBottomTextType3 {
        color: rgba(133, 133, 133, 1);
        font-size: 14px;
        text-align: center;
    }
    .FooterType3TopLogo {
        visibility: hidden;
    }
    .FooterType3BottomLogo {
        visibility: visible;
    }
  
}

/* (Tablet) */
@media screen and (min-width: 900px) and (max-width: 1199px) {
    .FooterDivider {
        background-color: rgba(67, 67, 67, 1);
    }
    .TippingPointLogomark {
        width: 48px;
        height: 46px;
    }
    .TippingPointLogoText {
        width: 143px;
        height: 24px;
    }
    .SocialMediaLinks {
        width: 34px;
        height: 34px;
        cursor: pointer; 
        color: #c1feaf;
        font-size: 34px !important;
    }
    .FooterLinkBlock {
        text-align: left;
    }
    .FooterHeader {
        font-size: 22px;
        color: rgba(193, 254, 175, 1);
    }
    .FooterSubtext {
        font-size: 14px;
        color: rgba(194,194,194,1);
        cursor: pointer;
    }
    .FooterBottomText {
        color: rgba(133, 133, 133, 1);
        font-size: 14px;
        text-align: left;
    }
    .FooterBottomTextType3 {
        color: rgba(133, 133, 133, 1);
        font-size: 14px;
        text-align: center;
    }
    .FooterType3TopLogo {
        visibility: hidden;
    }
    .FooterType3BottomLogo {
        visibility: visible;
    }
    
  
}

/* (Mobile) */
@media screen and (max-width: 899px) {
    .FooterDivider {
        background-color: rgba(67, 67, 67, 1);
    }
    .TippingPointLogomark {
        width: 18px;
        height: 18px;
    }
    .TippingPointLogoText {
        width: 100px;
        height: 18px;
    }
    .SocialMediaLinks { 
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: #c1feaf;
        font-size: 24px !important;
    }
    .FooterLinkBlock {
        text-align: left;
    }
    .FooterHeader {
        font-size: 16px;
        color: rgba(193, 254, 175, 1);
    }
    .FooterSubtext {
        font-size: 12px;
        color: rgba(194,194,194,1);
        cursor: pointer;
        text-wrap: nowrap;
    }
    .FooterBottomText {
        color: rgba(133, 133, 133, 1);
        font-size: 10px;
        text-align: center;
    }
    .FooterBottomTextType3 {
        color: rgba(133, 133, 133, 1);
        font-size: 10px;
        text-align: center;
    }
    .FooterType3TopLogo {
        visibility: visible;
        padding-bottom: 14px;
    }
    .FooterType3BottomLogo {
        visibility: hidden;
    }
  
}