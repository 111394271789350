.LanderDivider {
    position: relative;
    background-color: rgb(93, 93, 93);
    width: 100%;
    z-index: 1;
}


@media screen and (min-width: 1200px) {


    .LanderMainHeroComponentContainer {
        position: relative;
        max-width: 100%;
    }
    /* .LanderMainHeroComponent {
        height: 500px;
    } */
    .LanderMainHeroComponentHeader {
        font-size: 44px;
        text-align: center;
        color: rgba(193, 254, 175, 1);
        width: 100%;
    }
    .LanderMainHeroComponentSubtext {
        font-size: 44px;
        text-align: center;
        color: rgba(216, 216, 216, 1);
        width: 100%;
        line-height: 35px;
        align-self: center;
    }
    .LanderMainHeroComponentButton {
        width: 300px !important;
        background-color: #2a4fff !important;
        color: #f1f1f1 !important;
        border-radius: 50px !important;
        align-self: center !important;
        text-transform: capitalize !important;
        font-size: 22px !important;
    }
    .LanderMainHeroComponentHeroImage {
        position: relative;
        width: 100%;
    }
    .LanderMainHeroComponentHeroForeground {
        position: absolute;
        top: 51%;
        left: 42%;
        transform: translate(-50%, -50%);
        width: 47%
    }
    .LanderMainHeroComponentHeroBackground {
        position: relative;
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        width: 100%
    }
    .LanderMainHeroComponentBuiltOnBaseIcon {
        width: 150px;
        align-self: center;
    }
    .LanderMainHeroComponentButtonContainer {
        align-self: center;
        align-items: center;
        padding-top: 10px;
    }
    .LanderMainHeroComponentCreateButton {
        text-transform: none !important;
        width: 300px;
        background-color: #f1f1f1 !important;
        border-radius: 50px !important;
        color: #2a4fff !important;
        padding: 7.5px 0px !important;
        font-size: 20px !important;
    }

    .LanderEventShowcaseComponentHeader {
        font-size: 28px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
    }
    .LanderEventShowcaseComponentHeader .LanderEventShowcaseComponentHeaderAltColor {
        color: rgba(178, 194, 255, 1);
    }

    .LanderEventShowcaseComponentHeroImage {
        width: 90%;
    }
    .LanderEventShowcaseComponentFooter {
        width: 100%;
    }
    .LanderEventShowcaseComponentSubtext {
        /* font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left; */
        position: absolute;
        visibility: hidden;
    }

    .LanderCreateCampaignButton {
        width: 400px !important;
        height: 58px !important;
   
    }

    .LanderEventShowcaseComponentButton {
        text-transform: capitalize !important;
        background-color: rgba(193, 254, 175, 1) !important;
        color: #2e2e2e !important;
        border-radius: 10px !important;
        width: 400px !important;
        padding: 10px 0px !important;
        font-size: 22px !important;
    }

    .LanderFullWidthText {
        font-size: 34px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
        padding-top: 14px;
        padding-bottom: 10px;
        width: 80%
    }
    .LanderFullWidthText .LanderFullWidthTextAltColor {
        color: rgba(178, 194, 255, 1);
    }


    
    .LanderFunctionalityImage {
        color: rgba(193, 254, 175, 1);
        transform: scale(2.5);
        position: relative;
        left: 17%;
        padding-bottom: 20px;
    }
    .LanderFunctionalityHeader {
        font-size: 20px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }
    .LanderFunctionalitySubtext {
        font-size: 16px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }

    .LanderHeaderWithTextContainer {
        /* padding-left: 5%; */
        padding-top: 30px;
        text-align: center;
    }
    .LanderHeaderWithTextHeader {
        font-size: 34px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextSubtext {
        font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextImage {
        padding-top: 20px;
        width: 90%;
    }

    .CompetitorFeesComponentContainer {
        position: relative;
        width: 90%;
        padding: 20px 0px;
    }
    .CompetitorFeesComponentHeader {
        z-index: 1;
        font-size: 28px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleLeftHeader {
        z-index: 1;
        position: absolute;
        left: 35%;
        transform: translateX(-50%);
        font-size: 23px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleRightHeader {
        z-index: 1;
        position: absolute;
        left: 67%;
        transform: translateX(-50%);
        font-size: 23px;
        color: #f1f1f1;
        text-align: center;
    }
    
    .CompetitorFeesIcon{
        color: #c1feaf;
        font-size: 36px !important;
    }

    .CompetitorFeesIconRed{
        color: red;
        font-size: 36px !important;
    }
    .CompetitorFeesComponentSubtextLeft {
        z-index: 1;
        font-size: 24px;
        color: white;
        text-align: center;
        white-space: nowrap;
    }
    .CompetitorFeesComponentSubtextRight {
        z-index: 1;
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesMiddleLeftSubtext {
        z-index: 1;
        position: absolute;
        left: 35%;
        transform: translateX(-50%);
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesMiddleRightSubtext {
        z-index: 1;
        position: absolute;
        left: 65%;
        transform: translateX(-50%);
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesComponentDivider {
        z-index: 1;
        background-color: #a2a3a9;
        width: 100%;
    }
    .CompetitorFeesBackground {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        background: radial-gradient(circle, #2b2b2b 60%, #2e2e2e 70%);
        border-radius: 50%;
        padding: 6%;
        width: 400px;
    }

    .LanderFAQHeader {
        font-size: 28px;
        color: rgba(216, 216, 216, 1);
        text-align: center;
    }
    .LanderFAQSubtext {
        font-size: 16px;
        color:rgba(216, 216, 216, 1);
        text-align: center;
    }
    .LanderFAQButtonContainer {
        align-self: center;
        align-items: center;
        color: #2a4fff;
        padding-right: 10px;
        cursor: pointer;
    }
    .LanderFAQButtonText {
        font-size: 26px;
        font-weight: 600;
        color: white
    }

    .LanderNewsletterHeader {
        font-size: 18px;
        color: rgba(193, 254, 175, 1);
        text-align: center;
    }
    .LanderNewsletterInput {
        padding: 10px;
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterInputText {
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterMailIcon {
        padding-right: 20px;
    }
    .LanderNewsletterInput .LanderNewsletterButton {
        text-transform: capitalize;
        color: rgba(193, 254, 175, 1);
        border-color: rgba(193, 254, 175, 1);
        border-radius: 10px;
        width: 200px;
    }

    .LanderReturnToTopButton {
        color: #f1f1f1;
        padding: 20px;
        background-color: #2a4fff;
        border-radius: 10px;
        width: 450px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    

    /*
    *
    *    LanderMainHeroComponent
    *
    */
    .LanderMainHeroComponentContainer {
        position: relative;
        max-width: 100%;
    }
    .LanderMainHeroComponentHeader {
        font-size: 34px;
        text-align: center;
        color: #c1feaf;
        width: 100%;
    }

    .LanderMainHeroComponentButtonContainer {
        align-items: center;
        margin-bottom: 40px;
    }
    .LanderMainHeroComponentSubtext {
        font-size: 18px;
        text-align: center;
        color:  #d6d6d6;
        width: 70%;
        line-height: 30px;
    }
    .LanderMainHeroComponentButton {
        width: 300px;
        background-color: #2a4fff !important;
        color: #f1f1f1 !important;
        border-radius: 50px !important;
        align-self: center !important;
        text-transform: capitalize !important;
        padding: 10px 0px !important;
        font-size: 20px !important;
    }
    .LanderMainHeroComponentHeroImage {
        position: relative;
        width: 100%;
    }
    .LanderMainHeroComponentHeroForeground {
        position: absolute;
        top: 52%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 60%
    }
    .LanderMainHeroComponentHeroBackground {
        position: relative;
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        width: 105%;
        left: 50%;
        transform: translateX(-50%);
    }
    .LanderMainHeroComponentButtonContainer {
        align-self: center;
        align-items: center;
        padding-top: 10px;
    }
    .LanderMainHeroComponentCreateButton {
        text-transform: none !important;
        width: 300px;
        background-color: #f1f1f1 !important;
        border-radius: 50px !important;
        color: #2a4fff !important;
        padding: 10px 0px !important;
        font-size: 20px !important;
    }
    .LanderMainHeroComponentBuiltOnBaseIcon {
        width: 200px;
        align-self: center;
    }




    .LanderEventShowcaseComponentHeader {
        font-size: 28px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
    }
    .LanderEventShowcaseComponentHeader .LanderEventShowcaseComponentHeaderAltColor {
        color: rgba(178, 194, 255, 1);
    }

    .LanderEventShowcaseComponentHeroImage {
        width: 100%;
    }
    .LanderEventShowcaseComponentFooter {
        width: 100%;
    }
    .LanderEventShowcaseComponentSubtext {
        /* font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left; */
        position: absolute;
        visibility: hidden;
    }
    .LanderCreateCampaignButton {
        width: 300px !important;
        height: 58px !important;
   
    }
    .LanderEventShowcaseComponentButton {
        text-transform: capitalize !important;
        background-color: rgba(193, 254, 175, 1) !important;
        color: #2e2e2e !important;
        border-radius: 10px !important;
        width: 300px !important;
        padding: 10px !important;
        font-size: 24px !important;
        margin: 20px 0px !important;
    }

    .LanderFullWidthText {
        font-size: 32px;
        line-height: 35px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
        padding-top: 14px;
        padding-bottom: 10px;
        width: 80%;
    }
    .LanderFullWidthText .LanderFullWidthTextAltColor {
        color: rgba(178, 194, 255, 1);
    }


    /* .LanderFunctionalityImageContainer {
        padding: ;
    } */

    
    
    /* .LanderFunctionalityImageContainer {
        padding: ;
    } */

    
    .LanderFunctionalityImage {
        color: rgba(193, 254, 175, 1);
        transform: scale(2.5);
        position: relative;
        left: 17%;
        padding-bottom: 20px;
    }
    .LanderFunctionalityHeader {
        font-size: 20px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }
    .LanderFunctionalitySubtext {
        font-size: 16px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }

    .LanderHeaderWithTextContainer {
        /* padding-left: 5%; */
        padding-top: 30px;
        text-align: center;
    }
    .LanderHeaderWithTextHeader {
        font-size: 34px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextSubtext {
        font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextImage {
        padding-top: 20px;
        width: 90%;
    }

    .CompetitorFeesComponentContainer {
        position: relative;
        width: 85%;
        padding: 20px 0px;
    }
    .CompetitorFeesComponentHeader {
        z-index: 1;
        font-size: 28px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleLeftHeader {
        z-index: 1;
        position: absolute;
        left: 35%;
        transform: translateX(-50%);
        font-size: 23px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleRightHeader {
        z-index: 1;
        position: absolute;
        left: 65%;
        transform: translateX(-50%);
        font-size: 23px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesComponentSubtextLeft {
        z-index: 1;
        font-size: 18px;
        color: white;
        text-align: center;
        white-space: nowrap;
    }
    .CompetitorFeesComponentSubtextRight {
        z-index: 1;
        font-size: 18px;
        color: white;
        text-align: center;
    }
    
    .CompetitorFeesMiddleLeftSubtext {
        z-index: 1;
        position: absolute;
        left: 35%;
        transform: translateX(-50%);
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesMiddleRightSubtext {
        z-index: 1;
        position: absolute;
        left: 65%;
        transform: translateX(-50%);
        font-size: 18px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesComponentDivider {
        z-index: 1;
        background-color: #a2a3a9;
        width: 100%;
    }
    .CompetitorFeesBackground {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        background: radial-gradient(circle, #2b2b2b 60%, #2e2e2e 70%);
        border-radius: 50%;
        padding: 6%;
        width: 400px;
    }

    .LanderFAQHeader {
        font-size: 30px;
        color: rgba(216, 216, 216, 1);
        text-align: center;
        z-index: 1;
        position: relative;
    }
    .LanderFAQSubtext {
        font-size: 20px;
        color:rgba(216, 216, 216, 1);
        text-align: center;
        z-index: 1;
        
    }
    .LanderFAQButtonContainer {
        align-self: center;
        align-items: center;
        color: #2a4fff;
        padding-right: 30px;
    }
    .LanderFAQButtonText {
        font-size: 26px;
        color:white;
        font-weight: 600;
    }

    .LanderNewsletterHeader {
        font-size: 20px;
        color: rgba(193, 254, 175, 1);
        text-align: center;
    }
    .LanderNewsletterInput {
        padding: 10px;
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterInputText {
        font-size: 16px;
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterMailIcon {
        padding-right: 20px;
    }
    .LanderNewsletterInput .LanderNewsletterButton {
        text-transform: capitalize;
        font-size: 16px;
        color: rgba(193, 254, 175, 1);
        border-color: rgba(193, 254, 175, 1);
        border-radius: 10px;
        width: 200px;
    }

    .LanderReturnToTopButton {
        color: #f1f1f1;
        padding: 15px;
        background-color: #2a4fff;
        border-radius: 10px;
        width: 400px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .CompetitorFeesIcon{
        color: '#c1feaf';
        font-size: 35px;
    }
    .CompetitorFeesIconRed{
        color: red;
        font-size: 30px;
    }
}

@media screen and (max-width: 899px) {


    .LanderMainHeroComponentContainer {
        position: relative;
        max-width: 100%;
    }
    /* .LanderMainHeroComponent {
        height: 500px;
    } */
    .LanderMainHeroComponentHeader {
        font-size: 24px;
        text-align: center;
        color: rgba(193, 254, 175, 1);
        width: 100%;
    }
    .LanderMainHeroComponentSubtext {
        font-size: 18px;
        text-align: left;
        color: rgba(216, 216, 216, 1);
        width: 100%;
        line-height: 20px;
    }
    .LanderMainHeroComponentButton {
        width: 40%;
        min-width: 130px;
        background-color: #2a4fff !important;
        color: #f1f1f1 !important;
        border-radius: 50px !important;
        align-self: center !important;
        text-transform: capitalize !important;
        padding: 5px 0px !important;
        font-size: 15px !important;
    }
    .LanderMainHeroComponentHeroImage {
        position: relative;
        width: 100%;
    }
    .LanderMainHeroComponentHeroForeground {
        position: absolute;
        top: 50%;
        left: 56%;
        transform: translate(-50%, -50%);
        width: 230.57px;
    }
    .LanderMainHeroComponentHeroBackground {
        position: relative;
        /* top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        width: 272px;
    }
    .LanderMainHeroComponentHeroSubtext {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0%;
        visibility: hidden;
    }
    .LanderMainHeroComponentButtonContainer {
        align-self: center;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        width: 100%;
    }
    .LanderMainHeroComponentCreateButton {
        text-transform: none !important;
        width: 40%;
        min-width: 130px;
        background-color: #f1f1f1 !important;
        border-radius: 50px !important;
        color: #2a4fff !important;
        padding: 5px 0px !important;
        font-size: 15px !important;
    }
    .LanderMainHeroComponentBuiltOnBaseIcon {
        width: 99px;
        align-self: center;
    }

    .LanderEventShowcaseComponentHeader {
        font-size: 28px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
    }
    .LanderEventShowcaseComponentHeader .LanderEventShowcaseComponentHeaderAltColor {
        color: rgba(178, 194, 255, 1);
    }

    .LanderEventShowcaseComponentHeroImage {
        width: 100%;
    }
    .LanderEventShowcaseComponentFooter {
        width: 100%;
    }
    .LanderEventShowcaseComponentSubtext {
        /* font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left; */
        position: absolute;
        visibility: hidden;
    }
    .LanderCreateCampaignButton {
        width: 300px !important;
        height: 58px !important;
   
    }
    .LanderEventShowcaseComponentButton {
        text-transform: capitalize !important;
        background-color: rgba(193, 254, 175, 1) !important;
        color: #2e2e2e !important;
        border-radius: 10px !important;
        width: 300px !important;
        padding: 10px 0px !important;
        font-size: 18px !important;
    }

    .LanderFullWidthText {
        font-size: 28px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        padding-left: 10px;
        padding-top: 14px;
        padding-bottom: 10px;
    }
    .LanderFullWidthText .LanderFullWidthTextAltColor {
        color: rgba(178, 194, 255, 1);
    }


    /* .LanderFunctionalityImageContainer {
        padding: ;
    } */

    
    
    /* .LanderFunctionalityImageContainer {
        padding: ;
    } */

    
    .LanderFunctionalityImage {
        color: rgba(193, 254, 175, 1);
        transform: scale(2);
        position: relative;
        left: 17%;
        padding-bottom: 20px;
    }
    .LanderFunctionalityHeader {
        font-size: 15px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }
    .LanderFunctionalitySubtext {
        font-size: 16px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        position: relative;
        left: 10%;
        max-width: 80%;
    }

    .LanderHeaderWithTextContainer {
        /* padding-left: 5%; */
        padding-top: 30px;
        text-align: center;
    }
    .LanderHeaderWithTextHeader {
        font-size: 30px;
        color: rgba(193, 254, 175, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextSubtext {
        font-size: 18px;
        color: rgba(216, 216, 216, 1);
        text-align: left;
        z-index: 1;
    }
    .LanderHeaderWithTextImage {
        padding-top: 20px;
        width: 100%;
    }


    .CompetitorFeesComponentContainer {
        position: relative;
        width: 100%;
        padding: 20px 0px;
    }
    .CompetitorFeesComponentHeader {
        z-index: 1;
        font-size: 16px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleLeftHeader {
        z-index: 1;
        position: absolute;
        left: 38%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesMiddleRightHeader {
        z-index: 1;
        position: absolute;
        left: 70%;
        transform: translateX(-50%) translateY(-23px);
        font-size: 16px;
        color: #f1f1f1;
        text-align: center;
    }
    .CompetitorFeesComponentSubtextLeft {
        z-index: 1;
        font-size: 12px;
        color: white;
        text-align: left;
        width: 72px;
        line-height: 1.75;
        /* white-space: nowrap; */
    }
    .CompetitorFeesIcon{
        color: #c1feaf;
        font-size: 30px;
    }
    .CompetitorFeesIconRed{
        color: red;
        font-size: 30px;
    }
    .CompetitorFeesComponentSubtextRight {
        z-index: 1;
        font-size: 14px;
        color: white;
        text-align: right;
        width: 25%;
    }
    .CompetitorFeesMiddleLeftSubtext {
        z-index: 1;
        position: absolute;
        left: 38%;
        transform: translateX(-50%);
        font-size: 14px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesMiddleRightSubtext {
        z-index: 1;
        position: absolute;
        left: 70%;
        transform: translateX(-50%);
        font-size: 14px;
        color: white;
        text-align: center;
    }
    .CompetitorFeesComponentDivider {
        z-index: 1;
        background-color: #a2a3a9;
        width: 100%;
    }
    .CompetitorFeesBackground {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        background: radial-gradient(circle, #2b2b2b 60%, #2e2e2e 70%);
        border-radius: 50%;
        padding: 6%;
        width: 300px;
    }

    .LanderFAQHeader {
        font-size: 28px;
        color: rgba(216, 216, 216, 1);
        text-align: center;
    }
    .LanderFAQSubtext {
        font-size: 16px;
        color:rgba(216, 216, 216, 1);
        text-align: center;
    }
    .LanderFAQButtonContainer {
        align-self: center;
        align-items: center;
        color: #2a4fff;
        padding-right: 10px;
    }
    .LanderFAQButtonText {
        color:white;
        font-size: 26px;
        font-weight: 600;
    }

    .LanderNewsletterHeader {
        font-size: 18px;
        color: rgba(193, 254, 175, 1);
        text-align: center;
    }
    .LanderNewsletterInput {
        padding: 10px;
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterInputText {
        color: rgba(162, 163, 169, 1);
    }
    .LanderNewsletterInput .LanderNewsletterMailIcon {
        padding-right: 20px;
    }
    .LanderNewsletterInput .LanderNewsletterButton {
        text-transform: capitalize;
        color: rgba(193, 254, 175, 1);
        border-color: rgba(193, 254, 175, 1);
        border-radius: 10px;
        width: 200px;
    }

    .LanderReturnToTopButton {
        color: #f1f1f1;
        padding: 15px;
        background-color: #2a4fff;
        border-radius: 10px;
        width: 300px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    
}
