

/* mobile */
/* @media screen and (max-width: 899px) { */
    .ColoredBoxContainer {
        padding-top: 15px;
    }

    .ColoredBoxBackgroundOne {
        width: 333px;
        height: 100px;
        background-color: #9489EA;
        border-radius: 15px;
        position: relative;
    }
    .ColoredBoxBackgroundTwo {
        width: 333px;
        height: 100px;
        background-color: #5678de;
        border-radius: 15px;
        position: relative;
    }
    
    .ColoredBoxImage {
        position: relative;
        height: 100%;
        left: 36%;
    }
    .ColoredBoxBackgroundOne .ColoredBoxHeaderOne {
        position: absolute;
        top: 18%;
        left: 6%;
        color: #1f1f1f;
        font-size: 14px;
    }
    .ColoredBoxBackgroundOne .ColoredBoxButtonOne {
        position: absolute;
        top: 45%;
        left: 5%;
        /* transform: translate(-50%, -50%); */
        border-color: #c0fc9e;
        border-radius: 10px;
        height: 40%;
        min-width: 45%;
    }
    .ColoredBoxButtonCbOnRamp {
        position: absolute;
        top: 45%;
        left: 5%;
        height: 40%;
        min-width: 45%;
        border-radius: 10px;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        cursor: pointer;
        transform: none !important;
        transition: none !important;
        animation: none !important;
        color: #c0fc9e;
        border: 1px solid #c0fc9e;
    }

    .ColoredBoxButtonCbOnRamp:active,
    .ColoredBoxButtonCbOnRamp:hover,
    .ColoredBoxButtonCbOnRamp:focus {
        background: transparent;
        transform: none !important;
        transition: none !important;
        animation: none !important;
    }
    .ColoredBoxButtonOne .ColoredBoxButtonTextOne {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        color: #c0fc9e;
        text-wrap: nowrap;
    }
    .ColoredBoxButtonOne .ColoredBoxButtonIconOne {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        color: #c0fc9e;
    }

    .ColoredBoxBackgroundTwo .ColoredBoxHeaderTwo {
        position: absolute;
        top: 18%;
        left: 6%;
        color: #1f1f1f;
        font-size: 14px;
    }
    .ColoredBoxBackgroundTwo .ColoredBoxButtonTwo {
        position: absolute;
        top: 45%;
        left: 5%;
        /* transform: translate(-50%, -50%); */
        border-color: #c0fc9e;
        border-radius: 10px;
        height: 40%;
        min-width: 45%;
    }
    .ColoredBoxButtonTwo .ColoredBoxButtonTextTwo {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        color: #c0fc9e;
        text-wrap: nowrap;
    }
    .ColoredBoxButtonTwo .ColoredBoxButtonIconTwo {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        color: #c0fc9e;
    }
/* } */