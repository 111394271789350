


.SettingsHeader {
    font-size: 24px;
    color: #c1feaf;
    text-align: left;
}
.TextFieldLabel {
    color: #829aff !important;
    text-align: left;
}
.EditProfileButton {
    width: 250px;
    background-color: #c1feaf;
    padding: 10px 0px;
    border-radius: 10px;
    cursor: pointer;
}
.SetupUserIconContainer {
    position: relative;
    width: 100px;
    height: 100px;
}
.SetupUserInfoLabel {
    color: #829aff;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}
.SetupUserIcon {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
}
.SetupUserIconPlaceholder {
    background-color: #ffffff;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(4);
}